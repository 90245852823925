import { FormDataConsumer, FormTab, FormTabProps, SelectInput, SimpleFormIterator, email, required } from 'react-admin';

import CraftTwoColumns from 'src/components/CraftTwoColumns';
import TextInput from 'src/inputs/TextInput';
import BooleanInput from 'src/inputs/BooleanInput';
import ArrayInput from 'src/inputs/ArrayInput';
import ImageInput from 'src/inputs/ImageInput';
import CraftColorInput from 'src/components/CraftColorInput';
import IntegerInput from 'src/inputs/IntegerInput';
import tooltips from '../tooltips';
import { CraftPageSection } from 'src/components/CraftPageSection';
import { CraftDateInput } from 'src/components/CraftDateInput';
import { ORGANIZATION_TYPES, LOGO_MIME_TYPES } from 'src/utils/defaults/Constants';

const validateEmail = email();

const DetailsTab = ({ mode, ...rest }: Omit<FormTabProps, 'label'> & { mode: 'create' | 'edit' }) => (
  <FormDataConsumer>
    {({ formData }) => (
      <FormTab label="Details" {...rest}>
        <CraftPageSection title="General">
          <CraftTwoColumns
            left={
              <>
                <TextInput
                  source="name"
                  label="Organization name"
                  placeholder="ABC inc."
                  validate={required()}
                  fullWidth
                  autoFocus={mode === 'create'}
                />
                {mode === 'create' && (
                  <TextInput
                    source="admin_email_address"
                    label="Admin email address"
                    placeholder="admin@example.com"
                    validate={[required(), validateEmail]}
                    fullWidth
                  />
                )}
                <TextInput source="product_name" label="Product name" placeholder="Tech Radar" fullWidth />
                <SelectInput
                  source="type"
                  choices={ORGANIZATION_TYPES}
                  defaultValue="external"
                  isRequired
                  margin="none"
                  fullWidth
                />
              </>
            }
            right={
              <>
                <ImageInput
                  source="logo"
                  label="Organization logo"
                  accept={LOGO_MIME_TYPES.join(',')}
                  maxSize={10_000_000}
                />
                <ImageInput
                  source="settings.data.pdfLogoOverride"
                  label="PDF logo override"
                  accept={LOGO_MIME_TYPES.join(',')}
                  maxSize={10_000_000}
                  infoTooltip={tooltips.pdfLogoOverride}
                />
              </>
            }
          />
        </CraftPageSection>

        <CraftPageSection title="Craft for Teams">
          <CraftTwoColumns
            left={<BooleanInput source="settings.data.craftForTeamsSettings.enabled" label="Enable Craft for Teams" />}
            right={null}
          />
          {formData.settings?.data?.craftForTeamsSettings?.enabled && (
            <CraftTwoColumns
              left={
                <CraftDateInput source="settings.data.craftForTeamsSettings.trialStartDate" label="Trial Start Date" />
              }
              right={
                <CraftDateInput source="settings.data.craftForTeamsSettings.trialEndDate" label="Trial End Date" />
              }
            />
          )}

          <CraftTwoColumns left={<BooleanInput source="settings.data.limits.enabled" label="Limits" />} right={null} />
          {formData.settings?.data?.limits?.enabled && (
            <CraftTwoColumns
              left={
                <>
                  <IntegerInput source="settings.data.limits.portfolioLimit" label="Portfolio limit" />
                  <IntegerInput source="settings.data.limits.portfolioItemsLimit" label="Portfolio items limit" />
                  <IntegerInput source="settings.data.limits.userAccountLimit" label="User account limit" />
                </>
              }
              right={null}
            />
          )}
        </CraftPageSection>

        <CraftPageSection title="Custom View">
          <BooleanInput source="settings.data.customView.enabled" label="Enable Custom View" />
          {formData.settings?.data?.customView?.enabled && (
            <CraftTwoColumns
              left={
                <>
                  <TextInput
                    source="settings.data.customView.homePageOverride"
                    label="Homepage override"
                    infoTooltip={tooltips.homePageOverride}
                    sx={{ flex: 1 }}
                  />
                  <TextInput source="settings.data.customView.homepageTitle" label="Homepage title" fullWidth />
                  <TextInput
                    source="settings.data.customView.homepageMessage"
                    label="Homepage message"
                    multiline
                    fullWidth
                  />
                  <TextInput
                    source="settings.data.customView.homepageProductTitle"
                    label="Homepage product title"
                    fullWidth
                  />
                  <CraftColorInput
                    source="settings.data.customView.homepageProductTitleColor"
                    label="Homepage product title color"
                  />
                </>
              }
              right={
                <>
                  <TextInput
                    source="settings.data.customView.smallLogoUrl"
                    label="Small logo URL"
                    type="url"
                    infoTooltip={tooltips.smallLogoUrl}
                    sx={{ flex: 1 }}
                  />
                  <BooleanInput
                    source="settings.data.customView.hideHomeSidebarLink"
                    label="Hide Home Page in Sidebar"
                    infoTooltip={tooltips.hideHomeSidebarLink}
                  />
                  <BooleanInput
                    source="settings.data.customView.showPoweredByCraft"
                    label={`Show "Powered by Craft"`}
                  />
                  <BooleanInput
                    source="settings.data.customView.hideHelpCenterLink"
                    label={`Hide "Help Center" link`}
                  />
                  <BooleanInput
                    source="settings.data.customView.hideDefaultSearchSuggestions"
                    label="Hide default search suggestion"
                  />
                  <ArrayInput source="settings.data.customView.customSidebarLinks" label="Custom Sidebar Links">
                    <SimpleFormIterator fullWidth>
                      <TextInput source="iconUrl" label="Icon URL" type="url" validate={required()} fullWidth />
                      <TextInput source="title" label="Link Title" validate={required()} fullWidth />
                      <TextInput source="url" label="Link URL" type="url" validate={required()} fullWidth />
                      <BooleanInput source="enabled" label="Enabled" defaultValue fullWidth />
                    </SimpleFormIterator>
                  </ArrayInput>
                </>
              }
            />
          )}
        </CraftPageSection>

        <CraftPageSection title="Risk Dashboard">
          <CraftTwoColumns
            left={
              <>
                <BooleanInput source="risk_dashboard_access" label="Access to Risk Dashboard" />
                <BooleanInput
                  source="settings.data.featureFlags.hideDefaultRiskConfig"
                  label="Hide Default Configurations for Risk Hub"
                />
              </>
            }
            right={
              <BooleanInput
                source="settings.data.featureFlags.insights"
                label="Insights"
                infoTooltip={tooltips.insights}
              />
            }
          />
        </CraftPageSection>

        <CraftPageSection title="Craft Dashboards">
          <CraftTwoColumns
            left={
              <>
                <BooleanInput
                  source="craft_dashboards_access"
                  label="Access to Craft Dashboards"
                  infoTooltip={tooltips.craftDashboards}
                />
                {/* `format` prop is not supported on `ArrayInput` */}
                <ArrayInput
                  source="craft_dashboards_hosts_transformed"
                  label="Bundle hosts"
                  infoTooltip={tooltips.bundleHosts}
                >
                  <SimpleFormIterator fullWidth>
                    <TextInput source="value" label="Domain" validate={required()} fullWidth />
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            }
            right={null}
          />
        </CraftPageSection>

        <CraftPageSection title="Supply Horizon">
          <CraftTwoColumns
            left={
              <TextInput
                source="settings.data.intercomId"
                label="Intercom ID"
                placeholder="abc123_."
                infoTooltip={tooltips.intercomId}
                fullWidth
              />
            }
            right={null}
          />
          <CraftTwoColumns
            left={
              <BooleanInput
                source="settings.data.featureFlags.brandedEmails"
                label="Branded Emails"
                infoTooltip={tooltips.brandedEmails}
              />
            }
            right={null}
          />
        </CraftPageSection>

        <CraftPageSection title="N-Tier">
          <CraftTwoColumns
            left={<BooleanInput source="settings.data.featureFlags.nTier" label="N-Tier Supplier Network" />}
            right={null}
          />
          {formData.settings?.data?.featureFlags?.nTier && (
            <CraftTwoColumns
              left={
                <>
                  <BooleanInput
                    source="settings.data.featureFlags.nTierNew"
                    label='N-Tier "New"'
                    infoTooltip={
                      <span>This flag is now covered by the base N-Tier flag, and will soon be removed.</span>
                    }
                    defaultValue
                  />
                  <BooleanInput
                    source="settings.data.featureFlags.nTierCentralityScore"
                    label="N-Tier Centrality Score"
                  />
                  <BooleanInput source="settings.data.featureFlags.nTierImpact" label="Alert N-Tier Impact" />
                </>
              }
              right={null}
            />
          )}
        </CraftPageSection>

        <CraftPageSection title="Email Digests">
          <BooleanInput source="settings.data.notifications_transformed.activity" label="Activity Wall Digest" />
          <BooleanInput
            source="settings.data.notifications_transformed.needs_action"
            label="Company Request Needs Action Digest"
          />
          <BooleanInput source="settings.data.notifications_transformed.alerts" label="Alerts Digest" />
          <BooleanInput
            source="settings.data.notifications_transformed.company_requests_progress"
            label="Company Request Progress Digest"
          />
        </CraftPageSection>

        <CraftPageSection title="Predefined Content">
          <CraftTwoColumns
            left={
              <ArrayInput source="settings.data.suggestions" label="Suggestions">
                <SimpleFormIterator fullWidth>
                  <TextInput source="label" label="Label" validate={required()} fullWidth />
                  <TextInput source="url" label="Link URL" validate={required()} fullWidth />
                </SimpleFormIterator>
              </ArrayInput>
            }
            right={null}
          />
        </CraftPageSection>
      </FormTab>
    )}
  </FormDataConsumer>
);

export default DetailsTab;
