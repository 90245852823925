import { TransformData } from 'ra-core';

import { CraftEdit } from 'src/components/CraftEdit';
import { CompaniesEditHeader } from './EditHeader';
import CompaniesEditForm from './EditForm';
import { CompaniesEditAside } from './EditAside';

const transformValues: TransformData = (values) => {
  const result = {
    ...values,
    updated_at: new Date().toISOString(),
  };
  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }
  return result;
};

export const CompaniesEdit = () => (
  <CraftEdit
    aside={<CompaniesEditAside />}
    header={<CompaniesEditHeader />}
    mutationMode="pessimistic"
    transform={transformValues}
    redirect={false}
  >
    <CompaniesEditForm />
  </CraftEdit>
);

export default CompaniesEdit;
