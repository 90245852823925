import { useNotify, useRedirect } from 'react-admin';

import CraftCreate from 'src/components/CraftCreate';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';
import RiskSignalsTab from './formTabs/RiskSignals';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { createCriteriaGroup } from './helpers';
import { criteriaGroupSchema } from './validation';

export const CriteriaGroupCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSave = async (rawValues: Record<string, unknown>) => {
    const validationResult = criteriaGroupSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);

    const values = validationResult.data;
    const { data, errors } = await createCriteriaGroup(values);

    if (errors) {
      notify('Oops. There was a problem creating the risk scan. Please refresh and try again, or contact IT Support.', {
        type: 'error',
        autoHideDuration: 10000,
      });
      console.error('Error response on risk scan create:', errors);
      return undefined;
    }

    const criteriaGroupID = data?.insert_criteria_groups_one.id;
    if (!criteriaGroupID) {
      notify('Unable to redirect to risk scan: ID not returned', { type: 'error' });
      return redirect('list', 'criteria_groups');
    }

    return redirect('edit', 'criteria_groups', criteriaGroupID);
  };

  return (
    <CraftCreate header="Create Risk Scan">
      <CraftTabbedForm formType="create" onSubmit={handleSave}>
        <DetailsTab mode="create" />
        <RiskSignalsTab mode="create" />
      </CraftTabbedForm>
    </CraftCreate>
  );
};

export default CriteriaGroupCreate;
