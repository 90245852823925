import gql from 'graphql-tag';
import { ListAlt } from '@mui/icons-material';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import CriteriaGroupList from './List';
import CriteriaGroupShow from './Show';
import CriteriaGroupCreate from './Create';
import CriteriaGroupEdit from './Edit';
import { ResourceDefinition, ResourceSections } from 'src/types';

export const CriteriaGroupsResource: ResourceDefinition = {
  name: 'criteria_groups',
  section: ResourceSections.PORTAL,
  icon: ListAlt,
  options: { label: 'Risk Scans' },

  list: CriteriaGroupList,
  show: CriteriaGroupShow,
  create: CriteriaGroupCreate,
  edit: CriteriaGroupEdit,

  permissions: {
    list: ['criteriaGroup:read'],
    create: ['criteriaGroup:edit'],
    edit: ['criteriaGroup:edit'],
  },

  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'portal_criteria_groups',
      },
      [GET_ONE]: {
        operationName: 'portal_criteria_groups',
      },
      [GET_MANY]: {
        operationName: 'portal_criteria_groups',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'portal_criteria_groups',
      },
    },
    extraFields: gql`
      {
        criteria(order_by: { position: asc }) {
          criterion {
            id
            name
            archived_at
          }
          position
        }
      }
    `,
  },
};
