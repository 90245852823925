import { TabbedFormProps, useNotify, useRecordContext, useRefresh, useUpdate } from 'react-admin';

import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import LoadingOverlay from 'src/components/LoadingOverlay';
import ArchivedOrDeletedNotification from 'src/components/ArchivedOrDeletedNotification';
import DetailsTab from './formTabs/Details';
import EditAside from './EditAside';
import { CriterionRecord } from 'src/types';
import { editCriterionSchema, getFormValidator } from './validation';

const validateForm = getFormValidator('edit');

const transformValues = (rawValues: Partial<CriterionRecord>) => {
  const values = editCriterionSchema.parse(rawValues);
  return {
    ...values,
    updated_at: 'now()',
  };
};

const CustomTabbedForm = ({ children, ...rest }: TabbedFormProps) => {
  const record = useRecordContext<CriterionRecord>();
  const notify = useNotify();
  const refresh = useRefresh();

  const [softDeleteCriterion, { isLoading: softDeleteCriterionLoading }] = useUpdate();

  const handleDelete = async () => {
    if (!record) return;

    try {
      await softDeleteCriterion('criteria', { id: record.id, data: { archived_at: 'now()' } }, { returnPromise: true });
      notify('The Risk Signal has been successfully deleted', { type: 'success' });
      refresh();
    } catch (e) {
      console.error(e);
      notify('There was a problem deleting the Risk Signal. Please try again.', { type: 'error' });
    }
  };

  return (
    <>
      <CraftTabbedForm
        formType="edit"
        deleteOptions={{
          deletePermission: 'criteria:delete',
          onConfirmDelete: handleDelete,
          dialogTitle: `Delete Risk Signal "${record?.name}"`,
        }}
        validate={validateForm}
        shouldUnregister
        {...rest}
      >
        {children}
      </CraftTabbedForm>

      <LoadingOverlay open={softDeleteCriterionLoading} />
      <ArchivedOrDeletedNotification record={record} notificationText="This Risk Signal is ARCHIVED" />
    </>
  );
};

const CriteriaEdit = () => (
  <CraftEdit
    header="Edit Risk Signal"
    aside={<EditAside />}
    redirect={false}
    transform={transformValues}
    mutationMode="optimistic"
  >
    <CustomTabbedForm>
      <DetailsTab mode="edit" />
    </CustomTabbedForm>
  </CraftEdit>
);

export default CriteriaEdit;
