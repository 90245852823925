import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AutocompleteInput,
  Button,
  CreateBase,
  Loading,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
} from 'react-admin';

import client from 'src/data/api';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { SkippableFieldRecord, SkippedFieldRecord } from 'src/types';

const formatFieldName = (val: string) => val.replaceAll('_', ' ');

const CustomToolbar = () => {
  const { reset: resetForm, watch, formState } = useFormContext();
  const fieldIdValue: string | null = watch('id', null);

  useEffect(() => {
    if (formState.isSubmitSuccessful) resetForm();
  }, [formState]);

  if (!fieldIdValue) return null;

  return (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
      <Button type="submit" label="Add" variant="contained" />
      <Button type="reset" label="Cancel" variant="outlined" onClick={() => resetForm()} />
    </Toolbar>
  );
};

const SkippedFieldList = ({ skippedFields, refetch }: { skippedFields: SkippedFieldRecord[]; refetch: () => void }) => {
  const notify = useNotify();

  const [deleteSkippedField, { loading: deleteSkippedFieldLoading }] = useMutation(
    gql`
      mutation dataOrchestrator_deleteCompanySkippedField($id: ID!) {
        dataOrchestrator_deleteCompanySkippedField(id: $id)
      }
    `,
    { client, errorPolicy: 'all' },
  );

  const handleDeleteSkippedField = async (id: string) => {
    // TODO: remove this once delete mutation is fixed, and accepts the encoded id
    const decodedID = atob(id).split(':').at(-1);
    const { errors } = await deleteSkippedField({
      variables: { id: decodedID },
    });

    if (errors) {
      notify('Oops. There was a problem removing the skipped field. Please refresh the page and try again.', {
        type: 'error',
      });
      return;
    }

    refetch();
  };

  return (
    <>
      <Divider sx={{ width: '100%', my: 2 }} />
      <Box sx={{ maxHeight: 190, px: 2, overflowY: 'auto' }}>
        {skippedFields.map((field) => (
          <Stack key={field.id} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">{formatFieldName(field.companySkippableField.fieldName)}</Typography>
            <IconButton size="small" onClick={() => handleDeleteSkippedField(field.id)}>
              <Delete color="error" />
            </IconButton>
          </Stack>
        ))}
      </Box>

      <LoadingOverlay open={deleteSkippedFieldLoading} />
    </>
  );
};

const SkippedFieldsForm = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const {
    data: skippedFieldsData,
    error: skippedFieldsError,
    loading: skippedFieldsLoading,
    refetch: refetchSkippedFields,
  } = useQuery<{
    dataOrchestrator_companySkippedFields: {
      edges: {
        node: SkippedFieldRecord;
      }[];
    };
  }>(
    gql`
      query dataOrchestrator_companySkippedFields($companyIds: [ID!]!) {
        dataOrchestrator_companySkippedFields(companyIds: $companyIds) {
          edges {
            node {
              id
              companyId
              companySkippableField {
                id
                fieldName
              }
            }
          }
        }
      }
    `,
    {
      variables: { companyIds: [record?.id] },
      skip: !record,
      client,
      errorPolicy: 'all',
    },
  );

  const {
    data: skippableFieldsData,
    error: skippableFieldsError,
    loading: skippableFieldsLoading,
  } = useQuery<{ dataOrchestrator_companySkippableFields: SkippableFieldRecord[] }>(
    gql`
      query dataOrchestrator_companySkippableFields {
        dataOrchestrator_companySkippableFields {
          id
          fieldName
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  if (skippedFieldsError || skippableFieldsError) {
    notify(
      `Oops. There was a problem fetching skipped fields data. Please refresh the page, or contact \
IT Support if the problem persists.`,
      {
        type: 'error',
      },
    );
    console.error(skippedFieldsError || skippableFieldsError);
  }

  const [createSkippedField, { loading: createSkippedFieldLoading }] = useMutation(
    gql`
      mutation dataOrchestrator_createCompanySkippedField($companyID: ID!, $companySkippableFieldID: ID!) {
        dataOrchestrator_createCompanySkippedField(
          companyId: $companyID
          companySkippableFieldsId: $companySkippableFieldID
        ) {
          id
        }
      }
    `,
    { client, errorPolicy: 'all' },
  );

  const handleSave = async (values: { id?: string }) => {
    const { errors } = await createSkippedField({
      variables: {
        companyID: record.id,
        companySkippableFieldID: values.id,
      },
    });

    if (errors) {
      notify('Oops. There was a problem adding the skipped field. Please refresh the page and try again.', {
        type: 'error',
      });
      return;
    }

    refetchSkippedFields();
  };

  if (!record || skippedFieldsLoading) return <Loading />;

  // TODO: remove once sorting is implemented for query
  const skippedFieldsSorted =
    skippedFieldsData?.dataOrchestrator_companySkippedFields.edges
      .map((field) => field.node)
      .sort((a, b) => (a.companySkippableField.fieldName > b.companySkippableField.fieldName ? 1 : -1)) ?? [];
  const skippedFieldSkippableFieldIdSet = new Set<string>(
    skippedFieldsSorted.map((field) => field.companySkippableField.id),
  );

  // TODO: remove once sorting is implemented for query
  const skippableFieldsSorted = [...(skippableFieldsData?.dataOrchestrator_companySkippableFields ?? [])].sort((a, b) =>
    a.fieldName > b.fieldName ? 1 : -1,
  );

  return (
    <CreateBase>
      <SimpleForm onSubmit={handleSave} toolbar={<CustomToolbar />}>
        <AutocompleteInput
          source="id"
          label="Add a skipped field"
          choices={skippableFieldsSorted}
          isLoading={skippableFieldsLoading}
          getOptionDisabled={(field: SkippableFieldRecord) => skippedFieldSkippableFieldIdSet.has(field.id)}
          optionText={(field: SkippableFieldRecord) => formatFieldName(field.fieldName)}
          helperText={false}
          fullWidth
        />

        <LoadingOverlay open={createSkippedFieldLoading} />
      </SimpleForm>

      {skippedFieldsSorted.length ? (
        <SkippedFieldList skippedFields={skippedFieldsSorted} refetch={refetchSkippedFields} />
      ) : null}
    </CreateBase>
  );
};

export default SkippedFieldsForm;
