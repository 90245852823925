import { CraftCreate } from 'src/components/CraftCreate';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { SimpleForm, TextInput, SelectInput, useNotify, useRedirect, useGetOne, SimpleFormProps } from 'react-admin';
import { ORGANIZATION_SUBSCRIPTION_STATUSES } from 'src/utils/defaults/Constants';
import { orgSubscriptionsListRoute } from 'src/utils/routeHelpers';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import client from '../../../data/api';
import z from 'zod';
import { zodIssuesIntoErrorsMap } from '../../../utils/validation';

type OrgSubValues = {
  org_id?: string;
  status?: string;
  topic?: string;
};

export const companyCommoditiesSchema = z.object({
  org_id: z.string().trim().nonempty(),
  status: z.string().trim().nonempty(),
  topic: z.string().trim().nonempty(),
});

const validateOrgSubCreation = (values: Pick<SimpleFormProps, 'validate'> & OrgSubValues) => {
  const errors: Record<string, string> = {};
  const pattern = /(\w+):(\w+)/;
  if (!values.org_id) {
    errors.org_id = 'The "org_id" field is required';
  }
  if (!values.status) {
    errors.status = 'The "status" field is required';
  }
  if (!values.topic) {
    errors.topic = 'The "topic" field is required';
  }
  if (!values.topic?.match(pattern)) {
    errors.topic = 'The "topic" must be in the format <entity>:<attribute>';
  }
  if (
    !values.topic?.includes('company') &&
    !values.topic?.includes('resource') &&
    !values.topic?.includes('location')
  ) {
    errors.topic = 'The "topic" must contain at least one of the following: company, resource, location';
  }

  return errors;
};

export const OrgSubCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const location = useLocation();
  const [createEntitySubscription] = useMutation(
    gql`
      mutation insertEntitySubscription(
        $alertsParams: AlertsCreateEntitySubscriptionByTopicInput!
        $companySubscription: Boolean!
        $commoditySubscription: Boolean!
        $locationSubscription: Boolean!
      ) {
        alerts_createCompanySubscriptionByTopic(input: $alertsParams) @include(if: $companySubscription) {
          code
          success
          message
          payload {
            id
          }
        }

        alerts_createCommoditySubscriptionByTopic(input: $alertsParams) @include(if: $commoditySubscription) {
          code
          success
          message
          payload {
            id
          }
        }

        alerts_createLocationSubscriptionByTopic(input: $alertsParams) @include(if: $locationSubscription) {
          code
          success
          message
          payload {
            id
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );
  const [createEntitySubscriptionOld] = useMutation(
    gql`
      mutation insertEntitySubscriptionOld($params: organization_subscriptions_insert_input!) {
        signals_insert_organization_subscriptions(objects: [$params]) {
          returning {
            id
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const { orgId } = location.state ?? {};

  if (Number.isNaN(parseInt(orgId, 10))) {
    notify('Invalid organization id. A valid organization id is required to create an organization subscription.', {
      type: 'error',
    });
    redirect('/organization_config');
  }

  const handleSave = async (rawValues: Record<string, unknown>) => {
    const validationResult = companyCommoditiesSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;
    const entityType = values.topic.split(':')[0];

    const { errors, data } = await createEntitySubscription({
      variables: {
        alertsParams: {
          topicName: values.topic,
          organizationId: values.org_id,
        },
        companySubscription: entityType.includes('company'),
        commoditySubscription: entityType.includes('resource'),
        locationSubscription: entityType.includes('location'),
      },
    });

    const realData =
      data.alerts_createCompanySubscriptionByTopic ||
      data.alerts_createLocationSubscriptionByTopic ||
      data.alerts_createCommoditySubscriptionByTopic;

    if (errors || !realData?.success) {
      notify(errors?.[0]?.message || realData?.message, { type: 'error' });
      return null;
    }

    await createEntitySubscriptionOld({
      variables: {
        params: values,
      },
    });

    notify('Organization Subscription created', { type: 'success' });
    return redirect(orgSubscriptionsListRoute(values.org_id));
  };

  const { data = {}, isLoading, error } = useGetOne('organizations', { id: orgId });
  const choices = isLoading ? [] : [data];
  // Don't allow organization subscriptions to be created for organizations that don't exist
  if (error || (!isLoading && !data)) {
    notify('Unable to find organization. A valid organization is required to create an organization subscription.', {
      type: 'error',
    });
    redirect('/organization_config');
  }

  return (
    <CraftCreate>
      <SimpleForm toolbar={<CraftSimpleFormToolbar />} validate={validateOrgSubCreation} onSubmit={handleSave}>
        <h2>Add an organization subscription</h2>
        <SelectInput
          source="org_id"
          label="Organization"
          choices={choices}
          fullWidth
          optionText="name"
          optionValue="id"
          isLoading={isLoading}
          defaultValue={orgId}
          disabled
        />
        <TextInput source="topic" label="Topic" fullWidth isRequired />
        <SelectInput
          defaultValue="ACTIVE"
          source="status"
          fullWidth
          choices={ORGANIZATION_SUBSCRIPTION_STATUSES}
          isRequired
        />
      </SimpleForm>
    </CraftCreate>
  );
};

export default OrgSubCreate;
