import { useState } from 'react';
import { CraftCreate } from 'src/components/CraftCreate';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { SimpleForm, TextInput, useNotify, useRedirect, useGetOne } from 'react-admin';
import { companyCommodityListRoute } from 'src/utils/routeHelpers';
import { SharedInputs } from './SharedInputs';
import { transformToTopicType, transformValues } from './transformValues';
import { useLocation } from 'react-router-dom';
import { CommodityChoices } from './types';
import z from 'zod';
import { zodIssuesIntoErrorsMap } from '../../../utils/validation';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import client from '../../../data/api';

export const companyCommoditiesSchema = z.object({
  org_id: z.string().trim().nonempty(),
  company_id: z.string().trim().nonempty(),
  commodity_id: z.string().trim().nonempty(),
});

export const CompanyCommoditiesCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const location = useLocation();
  const [publishedCommodities, setPublishedCommodities] = useState<CommodityChoices>([]);
  const [createCompanyCommoditiesMapping] = useMutation(
    gql`
      mutation createCompanyCommoditiesMapping($params: AlertsCreateCommodityCompanyMappingByTopicInput!) {
        alerts_createCommodityCompanyMappingByTopic(input: $params) {
          code
          success
          message
          payload {
            id
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );
  const [createCompanyCommoditiesMappingOld] = useMutation(
    gql`
      mutation createCompanyCommoditiesMappingOld($params: company_commodities_insert_input!) {
        signals_insert_company_commodities(objects: [$params]) {
          returning {
            id
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const { orgId } = location.state ?? {};

  const handleSave = async (rawValues: Record<string, unknown>) => {
    const validationResult = companyCommoditiesSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const { errors, data } = await createCompanyCommoditiesMapping({
      variables: {
        params: {
          commodityTopic: transformToTopicType(values.commodity_id, 'resource'),
          companyTopic: transformToTopicType(values.company_id, 'company'),
          organizationId: values.org_id,
        },
      },
    });

    const realData = data.alerts_createCommodityCompanyMappingByTopic;

    if (errors || !realData?.success) {
      notify(errors?.[0]?.message || realData?.message, { type: 'error' });
      return null;
    }

    await createCompanyCommoditiesMappingOld({
      variables: {
        params: values,
      },
    });

    notify('Company Commodity created', { type: 'success' });
    return redirect(companyCommodityListRoute(values.org_id));
  };

  const { data = {}, isLoading, error } = useGetOne('organizations', { id: orgId });
  // Don't allow company commodities to be created for organizations that don't exist
  if (Number.isNaN(parseInt(orgId, 10)) || error || (!isLoading && !data)) {
    notify('Unable to find organization. A valid organization is required to create a company commodity.', {
      type: 'error',
    });
    redirect('/organizations');
  }

  return (
    <CraftCreate transform={transformValues}>
      <SimpleForm toolbar={<CraftSimpleFormToolbar />} onSubmit={handleSave}>
        <h2>Add a company commodity</h2>
        <TextInput source="org_id" label="Organization" fullWidth defaultValue={orgId} disabled required />
        <SharedInputs publishedCommodities={publishedCommodities} setPublishedCommodities={setPublishedCommodities} />
      </SimpleForm>
    </CraftCreate>
  );
};

export default CompanyCommoditiesCreate;
