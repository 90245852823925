import { FormTab, FormTabProps, required, TextInput } from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import JsonWithRawOptionInput from 'src/inputs/JsonWithRawOptionInput';

const DetailsTab = ({ mode, ...rest }: Omit<FormTabProps, 'label'> & { mode: 'create' | 'edit' }) => (
  <FormTab label="Details" {...rest}>
    <CraftPageSection title="General">
      <CraftTwoColumns
        left={<TextInput source="name" validate={required()} autoFocus={mode === 'create'} />}
        right={<TextInput source="description" validate={required()} multiline />}
      />
      <CraftTwoColumns
        left={
          <JsonWithRawOptionInput
            label="Search filters"
            source="search_filters"
            isRequired
            disabled={mode === 'edit'}
          />
        }
        right={null}
      />
    </CraftPageSection>
  </FormTab>
);

export default DetailsTab;
