import { TabbedFormProps, useNotify, useRecordContext, useRefresh, useUpdate } from 'react-admin';

import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import LoadingOverlay from 'src/components/LoadingOverlay';
import ArchivedOrDeletedNotification from 'src/components/ArchivedOrDeletedNotification';
import DetailsTab from './formTabs/Details';
import RiskSignalsTab from './formTabs/RiskSignals';
import EditAside from './EditAside';
import { CriteriaGroupRecord } from 'src/types';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { criteriaGroupSchema } from './validation';
import { updateCriteriaGroup } from './helpers';

const CustomTabbedForm = ({ children, ...rest }: TabbedFormProps) => {
  const record = useRecordContext<CriteriaGroupRecord>();
  const notify = useNotify();
  const refresh = useRefresh();

  const defaultValues = record
    ? {
        // these should be sorted by `position` via the default resource query
        criteria_transformed: record.criteria
          // we choose not to display these (by excluding from values), and allow them to be removed
          .filter(({ criterion: { archived_at } }) => !archived_at)
          .map(({ criterion: { id } }) => ({ id })),
      }
    : {};

  const [softDeleteCriteriaGroup, { isLoading: softDeleteCriteriaGroupLoading }] = useUpdate();

  const handleSave = async (rawValues: Record<string, unknown>) => {
    if (!record) return undefined;

    const validationResult = criteriaGroupSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);

    const values = validationResult.data;
    const criteriaGroupParams = {
      name: values.name,
      updated_at: 'now()',
    };
    const { errors } = await updateCriteriaGroup(criteriaGroupParams, values.criteria_transformed, record);

    if (errors) {
      console.error(errors);
      notify('Error updating Risk Scan. Please try again.', { type: 'error' });
      return undefined;
    }

    window.scroll(0, 0);
    notify('The Risk Scan has been successfully updated', { type: 'success' });
    refresh();

    return undefined;
  };

  const handleDelete = async () => {
    if (!record) return;

    try {
      await softDeleteCriteriaGroup(
        'criteria_groups',
        { id: record.id, data: { archived_at: 'now()' } },
        { returnPromise: true },
      );
      notify('The Risk Scan has been successfully deleted', { type: 'success' });
      refresh();
    } catch (e) {
      console.error(e);
      notify('There was a problem deleting the Risk Scan. Please try again.', { type: 'error' });
    }
  };

  return (
    <>
      <CraftTabbedForm
        formType="edit"
        defaultValues={defaultValues}
        onSubmit={handleSave}
        deleteOptions={{
          deletePermission: 'criteriaGroup:delete',
          onConfirmDelete: handleDelete,
          dialogTitle: `Delete Risk Scan "${record?.name}"`,
        }}
        // NOTE: DO NOT set `shouldUnregister` = true on forms with `ArrayInput`.
        // https://marmelab.com/react-admin/Inputs.html#usage
        shouldUnregister={false}
        {...rest}
      >
        {children}
      </CraftTabbedForm>

      <LoadingOverlay open={softDeleteCriteriaGroupLoading} />
      <ArchivedOrDeletedNotification record={record} notificationText="This Risk Scan is ARCHIVED" />
    </>
  );
};

const CriteriaGroupEdit = () => (
  <CraftEdit header="Edit Risk Scan" aside={<EditAside />} redirect={false} mutationMode="optimistic">
    <CustomTabbedForm>
      <DetailsTab mode="edit" />
      <RiskSignalsTab mode="edit" />
    </CustomTabbedForm>
  </CraftEdit>
);

export default CriteriaGroupEdit;
