import { FormTab } from 'react-admin';

import CraftTabbedForm from 'src/components/CraftTabbedForm';
import useEditSubmit from './useEditSubmit';
import GeneralTab from './formTabs/General';
import { Center } from 'src/components/Center';

const ComingSoonTabContent = () => (
  <Center width={1} minHeight="10rem">
    Coming soon
  </Center>
);

export const CompaniesEditForm = () => {
  const onSubmit = useEditSubmit();

  return (
    <CraftTabbedForm
      formType="edit"
      deleteOptions={{
        deletePermission: 'company:delete',
      }}
      onSubmit={onSubmit}
      disabled
    >
      <GeneralTab />
      <FormTab label="Metrics">
        <ComingSoonTabContent />
      </FormTab>
      <FormTab label="QA History">
        <ComingSoonTabContent />
      </FormTab>
    </CraftTabbedForm>
  );
};

export default CompaniesEditForm;
