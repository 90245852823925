import { FunctionField } from 'react-admin';
import { CoreDBCompanyRow } from '../models/companies/CoreDBCompanyTypes';

export const CompanyQASField = () => (
  <FunctionField
    render={(record: CoreDBCompanyRow) =>
      record.CompanyQAS ? (
        `${new Date(record?.CompanyQAS[0].updated_at)} by ${record.CompanyQAS[0].AdminUser.email}`
      ) : (
        <>&mdash;</>
      )
    }
  />
);

export default CompanyQASField;
