import {
  BulkExportButton,
  DateField,
  downloadCSV,
  ExportButton,
  Exporter,
  FunctionField,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
} from 'react-admin';
import CraftStandaloneList from '../../../components/CraftStandaloneList';
import DeleteBulkOrgSubs from '../organizationSubscriptions/BulkDelete';
import DeleteAllOrgSubs from '../organizationSubscriptions/DeleteAll';
import BulkImportOrgSubs from '../organizationSubscriptions/BulkImport';
import { orgSubscriptionsCreateRoute } from '../../../utils/routeHelpers';
import { Box, Button } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
import { Center } from '../../../components/Center';
import OrganizationSubscriptionPagination, {
  Filter,
  FilterValues,
  OnFilterChange,
} from '../organizationSubscriptions/OrganizationSubscriptionPagination';
import { CompanyReferenceField } from '../../companies/CompanyReferenceField';
import { WrapperStackField } from '../../../fields/WrapperStackField';
import DeleteOrgSub from '../organizationSubscriptions/DeleteButton';
import PublishedAlertsListButton from '../publishedAlerts/PublishedAlertsListButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CraftPageSection from '../../../components/CraftPageSection';
import { CraftDateInput } from '../../../components/CraftDateInput';
import { getOrgId } from '../../../utils/organizationSubscriptions';
import gql from 'graphql-tag';
import { client } from '../../../data/api';
import StyledLink from './StyledLink';
import { usePermissions } from '../../../hooks';
import { Notify } from '../../../utils/NotifyType';
import jsonExport from 'jsonexport/dist';

type SubscriptionType = 'company' | 'location' | 'resource';

type OrganizationSubscription = {
  id: string;
  topic: string;
  org_id: string;
  status: string;
  created_at: string;
  company_id: string;
  topic_metadata?: {
    metadata?: {
      name?: string;
    };
  };
};

export type Companies = {
  [key: string]: {
    name: string;
  };
};

type Props = {
  type: SubscriptionType;
  label: string;
};

const typeLabelMapping: Record<SubscriptionType, string> = {
  company: 'Company',
  location: 'Location',
  resource: 'Resource',
};

const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.blur();
  }
};

const convertFilterToWhere = (filterValues: FilterValues = {}) => {
  const whereVariables: Record<string, Record<string, string>>[] = [];
  Object.entries(filterValues).forEach(([maybeField, value]) => {
    if (maybeField.indexOf('@') !== -1) {
      const [field, operator] = maybeField.split('@');
      whereVariables.push({ [field]: { [operator]: value } });
    } else {
      whereVariables.push({ [maybeField]: { _ilike: `%${value}%` } });
    }
  });

  return whereVariables;
};

const fetchOrgSubAggregate: OnFilterChange = async (filterValues, filter) => {
  const orgId = filter?.org_id;
  if (!orgId) {
    return 0;
  }

  const orgSubAggregate = await client.query({
    query: gql`
      query SignalsOrganizationSubscriptionsAggregate($where: organization_subscriptions_bool_exp) {
        signals_organization_subscriptions_aggregate(where: $where) {
          aggregate {
            count(distinct: false)
          }
        }
      }
    `,
    variables: {
      where: {
        _and: [
          {
            org_id: {
              _eq: orgId,
            },
            ...(filter.topic ? { topic: { _ilike: `%${filter.topic}%` } } : {}),
          },
          ...convertFilterToWhere(filterValues),
        ],
      },
    },
    fetchPolicy: 'network-only',
  });

  return orgSubAggregate?.data?.signals_organization_subscriptions_aggregate?.aggregate?.count ?? 0;
};

const HandleOrganizationSubscriptionExporter =
  (notify: Notify): Exporter =>
  (organizationSubscriptions) => {
    const data = organizationSubscriptions.map((organizationSubscription: OrganizationSubscription) => ({
      Value: organizationSubscription?.topic_metadata?.metadata?.name ?? '',
      Topic: organizationSubscription.topic,
      'Created at': organizationSubscription.created_at,
    }));
    return jsonExport(
      data,
      {
        headers: ['Value', 'Topic', 'Created at'],
      },
      (error, csv) => {
        if (error) {
          const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
          notify(errorMessage, { type: 'error' });
        } else {
          downloadCSV(csv, 'organization_subscriptions');
        }
      },
    );
  };

export const SubscriptionTab: React.FC<Props> = ({ type, label }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const orgId = getOrgId();
  const listFilter = useMemo(() => ({ org_id: orgId, topic: type }), []);
  const [filter, setFilter] = useState<Filter>({});
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState<FilterValues>({});
  const canEdit = permissions.has('alerts:edit');
  const canDelete = permissions.has('alerts:delete');

  const subscriptionsFilters = [
    <TextInput key="topic" source="topic@_like" label="Topic" alwaysOn onKeyDown={handleKeyDown} />,
    <CraftDateInput key="createdAtFrom" label="Newer than" source="created_at@_gte" disallowFuture alwaysOn />,
    <CraftDateInput key="createdAtTo" label="Older than" source="created_at@_lte" alwaysOn />,
  ];

  const handleOrganizationSubscriptionExporter = HandleOrganizationSubscriptionExporter(notify);

  const handleOnFilterChange = useCallback(
    async (updatedFilterValues: FilterValues, updatedFilter: Filter = {}) => {
      const count = await fetchOrgSubAggregate(updatedFilterValues, updatedFilter);
      setFilter(updatedFilter);
      setFilterValues(updatedFilterValues);
      if (typeof count === 'number') {
        setTotal(count);
      }
    },
    [filterValues, filter],
  );

  const handleRefresh = useCallback(async () => {
    (async () => {
      const filterOverride = !filter?.org_id ? listFilter : filter;
      refresh();
      const count = await fetchOrgSubAggregate(filterValues, filterOverride);
      if (typeof count === 'number') {
        setTotal(count);
      }
    })();
  }, [filterValues, filter, refresh]);

  useEffect(() => {
    (async () => {
      const filterOverride = !filter?.org_id ? listFilter : filter;
      const count = await fetchOrgSubAggregate(filterValues, filterOverride);
      if (typeof count === 'number') {
        setTotal(count);
      }
    })();
  }, []);

  useEffect(() => {
    handleOnFilterChange(filterValues, filter);
  }, [filterValues, filter, handleOnFilterChange]);

  return (
    <CraftPageSection title={label}>
      <CraftStandaloneList
        resource="organization_subscriptions"
        perPage={10}
        filters={subscriptionsFilters}
        filter={listFilter}
        actions={
          <>
            {canDelete && <DeleteBulkOrgSubs refresh={handleRefresh} />}
            {canDelete && <DeleteAllOrgSubs refresh={handleRefresh} />}
            {canEdit && <BulkImportOrgSubs refresh={handleRefresh} />}
            {canEdit && (
              <StyledLink to={orgSubscriptionsCreateRoute} state={{ orgId }}>
                <Button startIcon={<AddRoundedIcon />} sx={{ height: '2rem' }}>
                  Create
                </Button>
              </StyledLink>
            )}
            <Box sx={{ px: 0.5, py: 1 }}>
              <ExportButton
                resource="organization_subscriptions"
                maxResults={5000}
                size="medium"
                sx={{ height: '2rem' }}
              />
            </Box>
          </>
        }
        dataGridProps={{
          empty: canEdit ? (
            <Center sx={{ marginTop: '1rem' }}>
              No organization subscriptions found. Click
              <StyledLink to={orgSubscriptionsCreateRoute} state={{ orgId }}>
                here
              </StyledLink>{' '}
              to create an organization subscription
            </Center>
          ) : (
            <Center sx={{ marginTop: '1rem' }}>No organization subscriptions found.</Center>
          ),
          bulkActionButtons: <BulkExportButton resource="organization_subscriptions" label="Bulk Export" />,
        }}
        CustomPaginationComponent={
          <OrganizationSubscriptionPagination total={total} onFilterChange={handleOnFilterChange} />
        }
        exporter={handleOrganizationSubscriptionExporter}
      >
        {type === 'company' ? (
          <CompanyReferenceField source="company_id" />
        ) : (
          <TextField label={typeLabelMapping[type]} source="topic_metadata.metadata.name" />
        )}
        <TextField source="topic" />
        <DateField source="created_at" showTime />
        <WrapperStackField label="Actions" stackProps={{ gap: 1 }}>
          {canDelete && <DeleteOrgSub refresh={handleRefresh} />}
          <FunctionField
            render={(r: OrganizationSubscription) => (
              <PublishedAlertsListButton filter={{ 'topics#topic@_eq': r.topic }} />
            )}
          />
        </WrapperStackField>
      </CraftStandaloneList>
    </CraftPageSection>
  );
};
