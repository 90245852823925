import React, { useState, useEffect } from 'react';
import { Button, SaveButton, useNotify, SimpleForm, useRecordContext } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Box, Typography } from '@mui/material';
import { LibraryAdd as LibraryAddIcon } from '@mui/icons-material';
import { client } from '../../../data/api';
import gql from 'graphql-tag';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { getOrgId, generateErrorMessage, buttonStyles } from 'src/utils/organizationSubscriptions';
import BulkToolbar from 'src/resources/signals/organizationSubscriptions/BulkToolbar';
import trim from 'lodash/trim';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import CsvImport from './CsvImport';
import CompanyCommoditiesTable, {
  getUpdateRecords,
  resetErrorMessages,
  validateCompanyCommodities,
} from './CompanyCommoditiesTable';
import { CompanyCommodityType } from './types';
import { BulkErrorNotification } from './BulkErrorNotification';
import { AssetLink } from 'src/resources/signals/organizationSubscriptions/AssetLink';
import { DialogInner } from 'src/resources/signals/organizationSubscriptions/DialogInner';
import { useMutation } from '@apollo/client';
import { transformToTopicType } from './transformValues';

const BulkImportToolbar = ({
  handleCloseClick,
  records,
  toolbarInfo,
}: {
  handleCloseClick: () => void;
  records: CompanyCommodityType[];
  toolbarInfo: string;
}) => {
  const buttonComponents = <SaveButton alwaysEnable label="import" />;

  const infoComponents = (
    <Typography variant="body2" color="textSecondary">
      {toolbarInfo}: {records.length?.toLocaleString()}
    </Typography>
  );

  return (
    <BulkToolbar
      handleCloseClick={handleCloseClick}
      infoComponents={infoComponents}
      buttonComponents={buttonComponents}
    />
  );
};

const BulkImport = ({ refresh }: { refresh: () => void }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [records, setRecords] = useState<CompanyCommodityType[]>([{ company_id: '', commodity_id: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const record = useRecordContext();
  const notify = useNotify();
  const buttonClasses = buttonStyles();

  const [bulkCreateCompanyCommoditiesMapping] = useMutation(
    gql`
      mutation bulkCreateCompanyCommoditiesMapping($input: AlertsBulkCreateCommodityCompanyMappingsByTopicInput!) {
        alerts_bulkCreateCommodityCompanyMappingsByTopic(input: $input) {
          code
          partialSuccessMessage
          successMessage
          errors {
            message
            input {
              companyTopic
              commodityTopic
            }
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (invalidMessage) {
      setShowNotification(true);
      setShowError(true);
    }
  }, [invalidMessage]);

  const handleCloseNotification = () => {
    setShowNotification(false);
    setInvalidMessage('');
  };

  const handleBulkImportButtonClick = () => {
    setIsLoading(false);
    setShowDialog(true);
  };

  const handleBulkImportCloseClick = () => {
    setIsLoading(false);
    setShowDialog(false);
    setShowError(false);
    const updatedRecords = getUpdateRecords();
    setRecords(updatedRecords);
    resetErrorMessages();
  };

  const handleSubmit: SubmitHandler<FieldValues> = async () => {
    resetErrorMessages();
    setInvalidMessage('');
    setShowError(false);
    const updatedInvalidMessage = validateCompanyCommodities();
    if (updatedInvalidMessage) {
      setInvalidMessage(updatedInvalidMessage);
      setShowError(true);
      return;
    }

    const finalRecords = getUpdateRecords();

    setIsLoading(true);

    try {
      const orgId = record?.org_id || getOrgId();
      if (!orgId) {
        throw new Error('No organization found');
      }

      let objects = uniqWith(
        Object.values(finalRecords).map((companyCommodity) => ({
          org_id: orgId,
          company_id: trim(companyCommodity.company_id),
          commodity_id: trim(companyCommodity.commodity_id).toLowerCase().replaceAll(' ', '_'),
        })),
        isEqual,
      );

      const { data: bulkResponseData } = await bulkCreateCompanyCommoditiesMapping({
        variables: {
          input: {
            organizationId: orgId,
            mappings: objects.map(({ commodity_id, company_id }) => ({
              companyTopic: transformToTopicType(company_id, 'company'),
              commodityTopic: transformToTopicType(commodity_id, 'resource'),
            })),
          },
        },
      });

      const realData = bulkResponseData.alerts_bulkCreateCommodityCompanyMappingsByTopic;

      if (realData.errors) {
        const errorMessage = realData.errors
          .map(
            (error: { message: string; input: { companyTopic: string; commodityTopic: string } }) =>
              `${error.message} for ${error.input.companyTopic} and ${error.input.commodityTopic}`,
          )
          .join('\n');
        notify(errorMessage, { type: 'error' });

        objects = objects.filter(
          ({ company_id, commodity_id }) =>
            !realData.errors.some(
              (error: { input: { companyTopic: string; commodityTopic: string } }) =>
                error.input.companyTopic === transformToTopicType(company_id, 'company') &&
                error.input.commodityTopic === transformToTopicType(commodity_id, 'resource'),
            ),
        );
      }

      const { data } = await client.mutate<{
        signals_insert_company_commodities: { affected_rows: number };
      }>({
        mutation: gql`
          mutation SignalsInsertCompanyCommodities($objects: [company_commodities_insert_input!]!) {
            signals_insert_company_commodities(objects: $objects) {
              affected_rows
            }
          }
        `,
        variables: {
          objects,
        },
      });

      const numAffectedRows = data?.signals_insert_company_commodities?.affected_rows || 0;
      setShowDialog(false);
      notify(`Successfully imported ${numAffectedRows.toLocaleString()} company commodities`);
      setRecords([{ company_id: '', commodity_id: '' }]);
      refresh();
    } catch (error) {
      setIsLoading(false);
      const errorMessage = generateErrorMessage({
        error,
        prependMessage: 'Failed to import company commodities.\n',
      });
      notify(errorMessage, { type: 'error' });
    }
  };

  const handleCsvUpload = (csvRecords: CompanyCommodityType[]) => {
    setRecords(csvRecords);
    setIsLoading(false);
    setShowError(false);
  };

  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem' }}>
      <Button onClick={handleBulkImportButtonClick} label="Import" size="medium" className={buttonClasses.saveButton}>
        <LibraryAddIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleBulkImportCloseClick} aria-label="Import Company Commodities">
        {isLoading && <LoadingOverlay open />}
        {!isLoading && (
          <DialogInner>
            <DialogTitle>Import Company Commodities</DialogTitle>
            <SimpleForm
              record={records}
              onSubmit={handleSubmit}
              toolbar={
                <BulkImportToolbar
                  handleCloseClick={handleBulkImportCloseClick}
                  records={records}
                  toolbarInfo="Total Records to Import"
                />
              }
            >
              <DialogContent sx={{ width: '100%' }}>
                <Box sx={{ marginTop: '-1rem' }}>
                  <AssetLink href="/assets/commodities_sample.csv">Download sample CSV</AssetLink>
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ marginBottom: '1rem' }}>
                  <CsvImport
                    onChange={handleCsvUpload}
                    setIsLoading={setIsLoading}
                    title="CSV File Upload for Import"
                    buttonClassName={buttonClasses.saveButton}
                  />
                </Box>
                <CompanyCommoditiesTable
                  setRecords={setRecords}
                  records={records}
                  setInvalidMessage={setInvalidMessage}
                  setShowError={setShowError}
                  showError={showError}
                />
                {invalidMessage && (
                  <BulkErrorNotification
                    message={invalidMessage}
                    showNotification={showNotification}
                    handleCloseNotification={handleCloseNotification}
                  />
                )}
              </DialogContent>
            </SimpleForm>
          </DialogInner>
        )}
      </Dialog>
    </Box>
  );
};

export default BulkImport;
