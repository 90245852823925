import gql from 'graphql-tag';
import z from 'zod';

import client from 'src/data/api';
import { CriteriaGroupRecord } from 'src/types';
import { criteriaGroupSchema } from './validation';

type FormValues = z.infer<typeof criteriaGroupSchema>;

export const createCriteriaGroup = (values: FormValues) =>
  client.mutate<{ insert_criteria_groups_one: { id: string } }>({
    mutation: gql`
      mutation insert_criteria_groups_one($criteriaGroupParams: criteria_groups_insert_input!) {
        insert_criteria_groups_one(object: $criteriaGroupParams) {
          id
        }
      }
    `,
    variables: {
      criteriaGroupParams: {
        name: values.name,
        criteria: {
          data: values.criteria_transformed.map(({ id }) => ({ criterion_id: id })),
        },
      },
    },
    errorPolicy: 'all',
  });

export const updateCriteriaGroup = async (
  criteriaGroupParams: Record<string, unknown>,
  criteriaUpdates: FormValues['criteria_transformed'],
  record: CriteriaGroupRecord,
) => {
  const oldCriteriaPositionsByIdMap: Record<string, number> = {};
  for (const { criterion, position } of record.criteria) {
    oldCriteriaPositionsByIdMap[criterion.id] = position;
  }

  const mappingUpdateParamsArr: { criterionID: string; position: number }[] = [];
  criteriaUpdates.forEach(({ id }, idx) => {
    // `position` indexing should be 1-based
    const newPosition = idx + 1;
    if (oldCriteriaPositionsByIdMap[id] !== newPosition) {
      mappingUpdateParamsArr.push({ criterionID: id, position: newPosition });
    }
  });

  return await client.mutate({
    mutation: gql`
      mutation updateCriteriaGroup(
        $criteriaGroupID: uuid!
        $criteriaGroupParams: criteria_groups_set_input!
        $mappingUpdates: [criteria_group_mappings_updates!]!
      ) {
        update_criteria_groups(where: { id: { _eq: $criteriaGroupID } }, _set: $criteriaGroupParams) {
          affected_rows
        }
        update_criteria_group_mappings_many(updates: $mappingUpdates) {
          affected_rows
        }
      }
    `,
    variables: {
      criteriaGroupID: record.id,
      criteriaGroupParams,
      mappingUpdates: mappingUpdateParamsArr.map(({ criterionID, position }) => ({
        where: { criterion: { id: { _eq: criterionID } } },
        _set: { position },
      })),
    },
    errorPolicy: 'all',
  });
};
