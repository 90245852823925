type CommodityChoice = {
  id: string;
  name: string;
};

export type CommodityChoices = CommodityChoice[];

export type SignalsAlertsType = {
  resource_id: string;
};

export type CompanyCommodityType = { commodity_id: string; company_id: string };

export enum ALERTS_SERVICE_DATASETS {
  NEWS_V2 = 'NEWS_V2',
  NEWS = 'NEWS',
  LOCATION_ALERTS = 'LOCATION_ALERTS',
  M_A_NEWS = 'M_A_NEWS',
  NEWS_COMMODITIES = 'NEWS_COMMODITIES',
  SALES_CUSTOM = 'SALES_CUSTOM',
}
