import { FormTab, FormTabProps, required, TextInput } from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';

const DetailsTab = ({ mode, ...props }: Omit<FormTabProps, 'label'> & { mode: 'create' | 'edit' }) => (
  <FormTab label="Details" {...props}>
    <CraftPageSection title="General">
      <CraftTwoColumns
        left={<TextInput source="name" validate={required()} autoFocus={mode === 'create'} />}
        leftSX={{ mb: 2 }}
        right={null}
      />
    </CraftPageSection>
  </FormTab>
);

export default DetailsTab;
