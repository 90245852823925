import { CompanyStatus, CompanyType } from '../../models/companies/CompanyTypes';
import { SearchEngineCompanyRowType } from '../../models/companies/SearchEngineCompanyTypes';
import { CoreDBCompanyRowStatusCode } from '../../models/companies/CoreDBCompanyTypes';

import { CompanyLocationsFieldFilterName, DataPackageKind } from 'src/types';
import { SignalsPublicationState, SignalsValidationState } from 'src/resources/signals/signals/SignalsEnums';
import { config } from '../../config/config.local';
import { ALERTS_SERVICE_DATASETS } from 'src/resources/signals/companyCommodities/types';

/**
 * Delimiter used to build natural IDs for resources without single-column primary keys.
 */
export const ID_KEY_DELIMITER = '~$~';

export const APP_BAR_HEIGHT = '4rem';
export const COREDB_COMPANY_STATUSES = [
  { id: 10, name: 'Draft', color: 'default' },
  { id: 20, name: 'Deleted', color: 'default' },
  { id: 30, name: 'Basic', color: 'default' },
  { id: 40, name: 'Active', color: 'success' },
  { id: 50, name: 'Closed', color: 'default' },
  { id: 60, name: 'Imported Active', color: 'default' },
  { id: 70, name: 'Imported Closed', color: 'default' },
];

export const SEARCH_ENGINE_COMPANY_TYPE_DEFAULTS: { id: SearchEngineCompanyRowType; name: CompanyType }[] = [
  { id: 'public', name: 'Public' },
  { id: 'private', name: 'Private' },
  { id: 'subsidiary', name: 'Subsidiary' },
  { id: 'investor', name: 'Investor' },
];

// https://craft-qa.s3.eu-west-1.amazonaws.com
export const COMPANY_LOGO_S3_BASE_URL = `${config.logo.cdnPrefix}/uploads/company/logo/`;
export enum COMPANY_STATUSES {
  DRAFT = 10,
  DELETED = 20,
  BASIC = 30,
  ACTIVE = 40,
  CLOSED = 50,
  IMPORTED_ACTIVE = 60,
  IMPORTED_CLOSED = 70,
}
export const MAPPED_CORE_DB_COMPANY_STATUS_CODES: Record<CoreDBCompanyRowStatusCode, CompanyStatus> = {
  [COMPANY_STATUSES.DRAFT]: 'Draft',
  [COMPANY_STATUSES.DELETED]: 'Deleted',
  [COMPANY_STATUSES.BASIC]: 'Basic',
  [COMPANY_STATUSES.ACTIVE]: 'Active',
  [COMPANY_STATUSES.CLOSED]: 'Closed',
  [COMPANY_STATUSES.IMPORTED_ACTIVE]: 'Imported Active',
  [COMPANY_STATUSES.IMPORTED_CLOSED]: 'Imported Closed',
};

export const ORGANIZATION_SUBSCRIPTION_STATUSES = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'PENDING_MANAGER_APPROVAL', name: 'Pending Manager Approval' },
  { id: 'PROCESSING', name: 'Processing' },
];

export const ORGANIZATION_TYPES = [
  { id: 'external', name: 'External' },
  { id: 'internal', name: 'Internal' },
];

export const COMMENT_ACCESS_TYPE = [
  { id: 'public', name: 'Public' },
  { id: 'private', name: 'Private' },
];

export const ALERTS_SERVICE_DATASETS_MAP = {
  news_v2: ALERTS_SERVICE_DATASETS.NEWS_V2,
  news: ALERTS_SERVICE_DATASETS.NEWS,
  location_alerts: ALERTS_SERVICE_DATASETS.LOCATION_ALERTS,
  m_a_news: ALERTS_SERVICE_DATASETS.M_A_NEWS,
  news_commodities: ALERTS_SERVICE_DATASETS.NEWS_COMMODITIES,
  sales_custom: ALERTS_SERVICE_DATASETS.SALES_CUSTOM,
};

export const ORGANIZATION_ALERTS_CONFIG_DATASETS = [
  { id: 'balance_sheet', name: 'Balance Sheet' },
  { id: 'news_commodities', name: 'Commodities (enable to modify Commodity Alerts)' },
  { id: 'employees', name: 'Employees' },
  { id: 'financial_scores_and_ratios', name: 'Financial Scores And Ratios' },
  { id: 'jobs', name: 'Jobs' },
  { id: 'keyword', name: 'Keyword' },
  { id: 'location_alerts', name: 'Location Alerts' },
  { id: 'locations', name: 'Locations' },
  { id: 'm_a_news', name: 'Mergers And Acquisitions News' },
  { id: 'news', name: 'News' },
  { id: 'news_v2', name: 'News v2' },
  { id: 'news_events', name: 'News Events' },
  { id: 'pnl', name: 'Profits and Loss' },
  { id: 'sales_custom', name: 'Sales Custom' },
];

export const ORGANIZATION_RISK_CONFIG_DATASETS = [
  { id: 'csr_insights', name: 'CSR Insights' },
  { id: 'cyber_insights', name: 'Cyber Insights' },
  { id: 'insolvency_insights', name: 'Insolvency Insights' },
  { id: 'liquidity_insights', name: 'Liquidity Insights' },
];

export const ORGANIZATION_CONFIG_DATASETS = [
  ...ORGANIZATION_ALERTS_CONFIG_DATASETS,
  ...ORGANIZATION_RISK_CONFIG_DATASETS,
];

export const SERVICE_PROVIDER_CERTIFICATE_EXPIRATIONS = [
  { id: 'current', name: 'Expires December 18, 2026' },
  { id: 'previous', name: 'Expires December 28, 2024' },
];

export const LOGO_MIME_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];

export const EXTERNAL_API_CLIENT_TYPES = [
  { id: 'paid', name: 'Paid' },
  { id: 'poc', name: 'POC' },
  { id: 'trial', name: 'Trial' },
  { id: 'internal', name: 'Internal' },
  { id: 'demo', name: 'Demo' },
  { id: 'churned', name: 'Churned' },
  { id: 'external', name: 'External' },
];

export const EXTERNAL_API_CLIENT_FEATURES = [
  { id: 'query.searchByProduct', name: 'Product Search' },
  { id: 'nTier', name: 'N-Tier' },
  { id: 'usgProhibitedLists_B', name: 'USG Prohibited List - Level B' },
];

export const VALIDATION_STATES = [
  { id: SignalsValidationState.VALID, name: 'Valid' },
  { id: SignalsValidationState.INVALID, name: 'Invalid' },
  { id: SignalsValidationState.IN_PROGRESS, name: 'Locked (In progress)' },
  { id: SignalsValidationState.PENDING, name: 'Pending' },
  { id: SignalsValidationState.OUTDATED, name: 'Outdated' },
];

export const PUBLICATION_STATES = [
  { id: SignalsPublicationState.DENIED, name: 'Denied' },
  { id: SignalsPublicationState.PUBLISHED, name: 'Published' },
];

export const DEFAULT_DATA_PACKAGE_NAME = '__default__';

export const DATA_PACKAGE_KINDS = [
  { id: DataPackageKind.STANDARD, name: 'Standard' },
  { id: DataPackageKind.CUSTOM, name: 'Custom' },
  { id: DataPackageKind.INTERNAL, name: 'Internal' },
];

export const API_CLIENT_DEFAULT_FIELD_PATHS = new Set(['id', 'slug', 'companyType', 'displayName', 'status']);

export const API_CLIENT_AVAILABILITY_READONLY_FIELD_PATHS = new Set(['securityRatingsFactorsAvailable']);

export const CLIENT_API_FIELD_POLICIES_BY_FIELD_PATH: Partial<
  Record<string, { filters: { id: string; name: string }[] }>
> = {
  locations: {
    filters: [{ id: CompanyLocationsFieldFilterName.HQ_ONLY, name: 'HQ Only' }],
  },
};

export const CLIENT_API_API_KEY_HEADER_NAME = 'X-CRAFT-API-KEY';

// TODO: rm once QA Portal starts using QA Client API
export const IS_LOCAL_OR_QA_ENV = ['local', 'qa'].includes(config.app.env);
