import React, { useEffect, useRef } from 'react';
import { useListContext, Pagination } from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';

export type FilterValues = {
  topic?: string;
  'created_at@_lt'?: string;
  'created_at@_gte'?: string;
};

export type Filter = {
  org_id?: string;
  topic?: string;
};

export type OnFilterChange = (filterValues: FilterValues, filter?: Filter) => Promise<void>;

export const OrganizationSubscriptionPagination = ({
  total,
  onFilterChange,
}: {
  total: number | null;
  onFilterChange: OnFilterChange;
}) => {
  const { filter, filterValues } = useListContext();
  const isCompMounted = useRef(false);

  useEffect(() => {
    if (!isCompMounted.current) {
      isCompMounted.current = true;
      return;
    }
    if (!filter?.org_id) {
      return;
    }
    onFilterChange(filterValues, filter);
  }, [filter, filterValues]);

  if (total === null) {
    return <Pagination />;
  }

  return (
    <Box>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">Total Records: {total?.toLocaleString()}</Typography>
        </Grid>
        <Grid item justifyContent="flex-end">
          <Pagination />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganizationSubscriptionPagination;
