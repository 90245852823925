import { useNotify, useRecordContext } from 'react-admin';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import client from '../../../data/api';
import CraftDeleteButtonWithConfirmation from '../../../components/CraftDeleteButtonWithConfirmation';
import { transformToTopicType } from './transformValues';

export const DeleteButton = ({ refresh }: { refresh: () => void }) => {
  const notify = useNotify();
  const record = useRecordContext();

  const [deleteCompanyCommodityMappingOld, { loading: loadingOld }] = useMutation(
    gql`
      mutation deleteCompanyCommoditiesMappingOld($id: bigint!) {
        signals_delete_company_commodities_by_pk(id: $id) {
          id
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const [deleteCompanyCommodityMapping, { loading }] = useMutation(
    gql`
      mutation deleteCompanyCommoditiesMapping($params: AlertsDeleteCommodityCompanyMappingByTopicInput!) {
        alerts_deleteCommodityCompanyMappingByTopic(input: $params) {
          success
          message
          payload {
            id
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );
  const handleDelete = async () => {
    const { errors, data } = await deleteCompanyCommodityMapping({
      variables: {
        params: {
          commodityTopic: transformToTopicType(record.commodity_id, 'resource'),
          companyTopic: transformToTopicType(String(record.company_id), 'company'),
          organizationId: record.org_id,
        },
      },
    });

    const realData = data.alerts_deleteCommodityCompanyMappingByTopic;

    if (errors || !realData?.success) {
      notify(errors?.[0]?.message || realData?.message, { type: 'error' });
      return null;
    }

    await deleteCompanyCommodityMappingOld({
      variables: {
        id: record.id,
      },
    });

    notify('Company commodity deleted', { type: 'success' });
    return refresh();
  };

  return (
    <CraftDeleteButtonWithConfirmation
      onDelete={handleDelete}
      loading={loading || loadingOld}
      title={`Delete company commodity #${record && record.id}`}
      content="Are you sure you want to delete this item?"
    />
  );
};

export default DeleteButton;
