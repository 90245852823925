import {
  TextField,
  EditButton,
  ExportButton,
  CreateButton,
  TopToolbar,
  AutocompleteArrayInput,
  SearchInput,
  SortPayload,
} from 'react-admin';
import { COREDB_COMPANY_STATUSES, SEARCH_ENGINE_COMPANY_TYPE_DEFAULTS } from 'src/utils/defaults/Constants';
import CompanyStatusField from 'src/fields/CompanyStatusField';
import CraftList from 'src/components/CraftList';
import { CompanyReferenceField } from './CompanyReferenceField';

const defaultSort: SortPayload = {
  field: 'id',
  order: 'ASC',
};

const columnStyles = {
  company_logo: {
    width: '3rem',
  },
};

const CompaniesListFilters = [
  <SearchInput key="searchFilter" placeholder="Search" source="name" alwaysOn />,
  <AutocompleteArrayInput key="statusFilter" source="state" choices={COREDB_COMPANY_STATUSES} alwaysOn />,
  <AutocompleteArrayInput
    key="typeFilter"
    source="company_type"
    choices={SEARCH_ENGINE_COMPANY_TYPE_DEFAULTS}
    alwaysOn
  />,
];

const CompaniesListActions = () => (
  <TopToolbar>
    <ExportButton />
    <CreateButton />
  </TopToolbar>
);

export const CompaniesList = () => (
  <CraftList
    title="All companies"
    filters={CompaniesListFilters}
    actions={<CompaniesListActions />}
    sort={defaultSort}
    columnStyles={columnStyles}
  >
    <CompanyReferenceField source="id" label="ID" />

    <TextField source="name" />
    <TextField source="company_type" label="Type" css={{ textTransform: 'capitalize' }} />
    <CompanyStatusField label="Status" />
    <EditButton />
  </CraftList>
);

export default CompaniesList;
