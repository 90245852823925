import { Box, Button } from '@mui/material';
import { Confirm } from 'react-admin';
import { useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';

type Props = {
  title: string;
  content: string;
  onDelete: () => void;
  loading?: boolean;
};

export const DeleteButton = ({ onDelete, loading, title, content }: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteClick = () => setDeleteModalOpen(true);
  const handleDeleteClose = () => setDeleteModalOpen(false);
  const handleDelete = () => {
    onDelete();
    setDeleteModalOpen(false);
  };

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Button startIcon={<DeleteIcon />} size="small" color="error" onClick={handleDeleteClick}>
        Delete
      </Button>
      <Confirm
        isOpen={deleteModalOpen}
        loading={!!loading}
        title={title}
        content={content}
        onConfirm={handleDelete}
        onClose={handleDeleteClose}
      />
    </Box>
  );
};

export default DeleteButton;
