import CraftCreate from 'src/components/CraftCreate';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';
import { createCriterionSchema, getFormValidator } from './validation';
import { CriterionRecord } from 'src/types';

const validateForm = getFormValidator('create');

const transformValues = (rawValues: Partial<CriterionRecord>) => {
  const values = createCriterionSchema.parse(rawValues);
  return {
    ...values,
    updated_at: 'now()',
  };
};

export const CriterionCreate = () => {
  const defaultValues = { search_filters: {} };

  return (
    <CraftCreate header="Create Risk Signal" transform={transformValues}>
      <CraftTabbedForm formType="create" defaultValues={defaultValues} validate={validateForm}>
        <DetailsTab mode="create" />
      </CraftTabbedForm>
    </CraftCreate>
  );
};

export default CriterionCreate;
