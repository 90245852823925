import { Alert, Typography } from '@mui/material';
import {
  AutocompleteInput,
  FormTab,
  FormTabProps,
  required,
  SimpleFormIterator,
  useGetList,
  useNotify,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import ArrayInput from 'src/inputs/ArrayInput';
import { CriterionRecord } from 'src/types';

const RiskSignalsTab = ({ mode, ...rest }: Omit<FormTabProps, 'label'> & { mode: 'create' | 'edit' }) => {
  const { watch } = useFormContext();
  const notify = useNotify();
  const criteriaValue: { id: string }[] = watch('criteria_transformed', []);

  const {
    data: criteriaData,
    isLoading: criteriaDataIsLoading,
    error: criteriaDataError,
  } = useGetList<CriterionRecord>('criteria', {
    filter: { 'archived_at@_is_null': true },
    sort: { field: 'name', order: 'ASC' },
    meta: { noLimit: true },
  });

  if (criteriaDataError) {
    notify('There was a problem fetching Risk Signals. Please try again.', { type: 'error' });
  }

  const selectedRiskSignalIdSet = new Set(criteriaValue.map(({ id }) => id));

  const formIteratorProps = mode === 'edit' && {
    disableAdd: true,
    disableRemove: true,
    disableClear: true,
  };

  return (
    <FormTab label="Risk Signals" {...rest}>
      <CraftPageSection title="Risk Signals">
        {mode === 'edit' ? (
          <Alert severity="info" sx={{ mb: 1 }}>
            Signals cannot be added or removed from a Risk Scan, but they can be reordered (hover over a signal for
            options).
          </Alert>
        ) : (
          <Typography variant="caption" mb={1}>
            Add/remove signals (min. 1 required)
          </Typography>
        )}

        <CraftTwoColumns
          left={
            <ArrayInput source="criteria_transformed" label={false} isLoading={criteriaDataIsLoading}>
              <SimpleFormIterator fullWidth {...formIteratorProps}>
                <AutocompleteInput
                  source="id"
                  label="Signal"
                  choices={criteriaData ?? []}
                  getOptionDisabled={(criterion: CriterionRecord) => selectedRiskSignalIdSet.has(criterion.id)}
                  helperText={false}
                  validate={required()}
                  readOnly={mode === 'edit'}
                  fullWidth
                />
              </SimpleFormIterator>
            </ArrayInput>
          }
          leftSX={{ mb: 2 }}
          right={null}
        />
      </CraftPageSection>
    </FormTab>
  );
};

export default RiskSignalsTab;
