import { Box, Paper, Typography, styled } from '@mui/material';
import { ArrayInputProps as ArrayInputRaProps, ArrayInput as ArrayInputRA } from 'react-admin';

import WithInfoTooltip from 'src/components/WithInfoTooltip';
import { CommonInputProps } from './types';

const LabelContent = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const InputContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledArrayInput = styled(ArrayInputRA)(({ theme }) => ({
  margin: 0,
  '& .RaSimpleFormIterator-buttons': {
    justifyContent: 'center',
  },
  '& .RaSimpleFormIterator-line:not(:last-of-type)': {
    marginBottom: theme.spacing(1),
  },
}));

interface ArrayInputProps extends ArrayInputRaProps, CommonInputProps {}

const CustomLabel = ({ label, infoTooltip }: Pick<ArrayInputProps, 'label' | 'infoTooltip'>) =>
  infoTooltip ? (
    <WithInfoTooltip title={infoTooltip} sx={{ mt: '0.3em', ml: 1 }}>
      <LabelContent>{label}</LabelContent>
    </WithInfoTooltip>
  ) : (
    <LabelContent>{label}</LabelContent>
  );

export const ArrayInput = ({ label, infoTooltip, children, ...rest }: ArrayInputProps) => (
  <Box>
    {label && <CustomLabel label={label} infoTooltip={infoTooltip} />}

    <InputContainer>
      <StyledArrayInput
        label={false}
        helperText={false} // form validation errors will continue to show
        {...rest}
      >
        {children}
      </StyledArrayInput>
    </InputContainer>
  </Box>
);

export default ArrayInput;
