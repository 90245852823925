import { z } from 'zod';

import { CriterionRecord } from 'src/types';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';

const criterionSchema = z.object({
  name: z.string().trim().nonempty(),
  description: z.string().trim().nonempty(),
  search_filters: z.record(z.string(), z.any()),
});

export const createCriterionSchema = criterionSchema;

export const editCriterionSchema = criterionSchema.omit({ search_filters: true });

export const getFormValidator = (kind: 'create' | 'edit') => (values: Partial<CriterionRecord>) => {
  const schema = kind === 'create' ? createCriterionSchema : editCriterionSchema;
  const validationResult = schema.safeParse(values);
  return validationResult.success ? {} : zodIssuesIntoErrorsMap(validationResult.error.issues);
};
