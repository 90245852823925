import { Box, Card } from '@mui/material';
import { Labeled } from 'react-admin';

import CompanyCreatedAtField from '../../fields/CompanyCreatedAtField';
import CompanyUpdatedAtField from '../../fields/CompanyUpdatedAtField';
import CompanyQASField from '../../fields/CompanyQASField';
import NotesSection from './Notes/NotesSection';
import SkippedFieldsForm from './SkippedFieldsForm';
import { CraftTwoColumns } from 'src/components/CraftTwoColumns';

export const CompaniesEditAside = () => (
  <Box ml={2} minWidth="25rem">
    <Card sx={{ p: 2 }}>
      <CraftTwoColumns
        left={
          <Labeled label="Created">
            <CompanyCreatedAtField />
          </Labeled>
        }
        right={
          <Labeled label="Updated">
            <CompanyUpdatedAtField />
          </Labeled>
        }
      />
    </Card>
    <Card sx={{ p: 2 }}>
      <Labeled label="Verified">
        <CompanyQASField />
      </Labeled>
    </Card>
    <h3>Skipped Fields</h3>
    <Card sx={{ p: 2 }}>
      <SkippedFieldsForm />
    </Card>
    <h3>Notes</h3>
    <Card sx={{ p: 2 }}>
      <NotesSection />
    </Card>
  </Box>
);
