export const companyCommodityListRoute = (orgId: string | number) => `/organization_config/${orgId}/4`;

export const companyCommodityCreateRoute = '/company_commodities/create';

export const orgConfigListRoute = (orgId: string | number) => `/organization_config/${orgId}`;

export const orgSubscriptionsListRoute = (orgId: string | number) => `/organization_config/${orgId}/1`;

export const orgSubscriptionsCreateRoute = '/organization_subscriptions/create';

export const userConfigCreateRoute = (userId: string | number) => `/user_config/create?userId=${userId}`;

export const userSubscriptionsCreateRoute = (userId: string | number) => `/user_subscriptions/create?userId=${userId}`;

export const userSubscriptionsListRoute = (userId: string | number) => `/user_config/${userId}/1`;

export enum ExternalApiClientEditTab {
  DETAILS = 0,
  SUBSCRIPTIONS = 1,
  FIELDS = 2,
  FEATURES = 3,
  RISKS = 4,
}
export const externalApiClientEditRoute = (
  externalApiClientId: string | number,
  tab?: ExternalApiClientEditTab,
  urlSearchParams?: Record<string, string | number>,
) => appendSearchParams(`/external_api_clients/${externalApiClientId}${tab ? `/${tab}` : ''}`, urlSearchParams);

export enum OrganizationEditTab {
  DETAILS = 0,
  FEATURE_FLAGS = 1,
  INTERNAL_SETTINGS = 2,
  SUBSCRIPTION = 3,
  USERS = 4,
}
export const organizationEditRoute = (orgID: string | number, tab?: OrganizationEditTab) =>
  `/organizations/${orgID}${tab ? `/${tab}` : ''}`;

export const companySubscriptionSetCreateRoute = (externalApiClientID: string | number) =>
  `/company_subscription_sets/create?externalApiClientID=${externalApiClientID}`;

// formatters and subHelpers ------------------------
function appendSearchParams(url: string, searchParams?: Record<string, string | number>) {
  if (searchParams) {
    const queryParams = new URLSearchParams();
    for (const [key, value] of Object.entries(searchParams)) {
      queryParams.append(key, value.toString());
    }
    return `${url}?${queryParams.toString()}`;
  }
  return url;
}
