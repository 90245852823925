import React, { useState, useEffect } from 'react';
import { Stack, Typography, Button, Box } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
import {
  BooleanInput,
  DateField,
  FormTab,
  Labeled,
  TextField,
  TextInput,
  WithRecord,
  WrapperField,
  TransformData,
  CheckboxGroupInput,
  useRefresh,
  ExportButton,
  BulkExportButton,
  downloadCSV,
  Exporter,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  SelectArrayInput,
} from 'react-admin';
import { Center } from 'src/components/Center';
import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import { CraftPageSection } from 'src/components/CraftPageSection';
import CraftStandaloneList from 'src/components/CraftStandaloneList';
import { CompanyReferenceField } from 'src/resources/companies/CompanyReferenceField';
import { OrganizationReferenceField } from 'src/resources/portal/organizations/OrganizationReferenceField';
import {
  ALERTS_SERVICE_DATASETS_MAP,
  ORGANIZATION_ALERTS_CONFIG_DATASETS,
  ORGANIZATION_RISK_CONFIG_DATASETS,
} from 'src/utils/defaults/Constants';
import { handleCheckboxGroupInputClick, checkboxGroupInputDefaultCursorSx } from 'src/utils/checkboxGroupInput';
import gql from 'graphql-tag';
import { client } from '../../../data/api';
import { getOrgId } from 'src/utils/organizationSubscriptions';
import jsonExport from 'jsonexport/dist';
import { Notify } from 'src/utils/NotifyType';
import startCase from 'lodash/startCase';
import { companyCommodityCreateRoute, companyCommodityListRoute } from 'src/utils/routeHelpers';
import DeleteAllCompanyCommodities from 'src/resources/signals/companyCommodities/DeleteAll';
import BulkImportCompanyCommodities from 'src/resources/signals/companyCommodities/BulkImport';
import BulkDeleteCompanyCommodities from 'src/resources/signals/companyCommodities/BulkDelete';
import { Link } from 'react-router-dom';
import { StyledLink } from './StyledLink';
import { usePermissions } from 'src/hooks';
import { hiddenSpan } from './hiddenSpan';
import { Companies, SubscriptionTab } from './SubscriptionTab';
import DeleteButton from '../companyCommodities/DeleteButton';
import { redirect } from 'react-router';
import { useMutation } from '@apollo/client';

type CompanyCommodity = {
  id: string;
  commodity_id: string;
  company_id: string;
  org_id: string;
};

type OrganizationConfig = {
  data: { org_id: number; api_client_id?: string; enable_alerts_processing: boolean; datasets: string };
};

const formatArrayForGraphQL = (datasets: string[]) => {
  const jsonString = JSON.stringify(datasets);
  return jsonString.replace('[', '{').replace(']', '}');
};

const transformBackToArray = (formattedString: string) => {
  // Replace curly braces back to square brackets
  const jsonString = formattedString.replace('{', '[').replace('}', ']');

  // Parse the JSON string into an array
  return JSON.parse(jsonString);
};

const transformValues: TransformData = (values) => {
  const result = {
    ...values,
    updated_at: new Date().toISOString(),
  };
  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }
  result.datasets = values.datasets.length ? formatArrayForGraphQL(values.datasets) : '{}';
  return result;
};

const mapDatasetsToAlerts = (datasets: string[]) =>
  datasets.reduce((prev, curr) => {
    if (ALERTS_SERVICE_DATASETS_MAP[curr as keyof typeof ALERTS_SERVICE_DATASETS_MAP]) {
      return [...prev, ALERTS_SERVICE_DATASETS_MAP[curr as keyof typeof ALERTS_SERVICE_DATASETS_MAP]];
    }

    return prev;
  }, [] as string[]);

const transformAlertServiceValues = (values: OrganizationConfig) => ({
  id: values.data.org_id,
  clientApiId: values.data.api_client_id,
  alertsProcessingEnabled: values.data.enable_alerts_processing,
  datasets: mapDatasetsToAlerts(transformBackToArray(values.data.datasets)),
});

const HandleCompanyCommodityExporter =
  (notify: Notify): Exporter =>
  async (companyCommodities, fetchRelatedRecords, dataProvider) => {
    let orgName = '';
    if (companyCommodities[0]?.org_id) {
      try {
        const result = await dataProvider.getOne('organizations', { id: companyCommodities[0].org_id });
        orgName = result?.data?.name ?? '';
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
        notify(errorMessage, { type: 'error' });
      }
    }
    fetchRelatedRecords(companyCommodities, 'company_id', 'companies').then((companies: Companies) => {
      const data = companyCommodities.map((companyCommodity: CompanyCommodity) => ({
        Org: `${orgName} (${companyCommodity.org_id})`,
        Company: `${
          companies[companyCommodity.company_id]?.name ? `${companies[companyCommodity.company_id].name} ` : ''
        }(${companyCommodity.company_id})`,
        Commodity: companyCommodity.commodity_id,
      }));
      return jsonExport(
        data,
        {
          headers: ['Org', 'Company', 'Commodity'],
        },
        (error, csv) => {
          if (error) {
            const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
            notify(errorMessage, { type: 'error' });
          } else {
            downloadCSV(csv, 'company_commodities');
          }
        },
      );
    });
  };

const fetchCommodityChoices = async () => {
  const orgId = getOrgId();
  if (!orgId) {
    return [];
  }

  const commodityChoices = await client.query<{
    signals_company_commodities: CompanyCommodity[];
  }>({
    query: gql`
      query SignalsCompanyCommodities(
        $where: company_commodities_bool_exp
        $distinct_on: [company_commodities_select_column!]
        $order_by: [company_commodities_order_by!]
      ) {
        signals_company_commodities(where: $where, distinct_on: $distinct_on, order_by: $order_by) {
          commodity_id
        }
      }
    `,
    variables: {
      where: {
        org_id: {
          _eq: orgId,
        },
      },
      distinct_on: ['commodity_id'],
      order_by: [{ commodity_id: 'asc' }],
    },
  });

  return commodityChoices.data.signals_company_commodities.map((companyCommodity) => ({
    id: companyCommodity.commodity_id,
    name: startCase(companyCommodity.commodity_id.replaceAll('_', ' ')),
  }));
};

export const OrgConfigEdit = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const canEdit = permissions.has('alerts:edit');
  const canDelete = permissions.has('alerts:delete');

  const handleCompanyCommodityExporter = HandleCompanyCommodityExporter(notify);

  const [commodityChoices, setCommodityChoices] = useState<{ id: string; name: string }[]>([]);
  const orgId = getOrgId();
  const commodityDeleteButtonRedirectTo = companyCommodityListRoute(orgId);

  const [updateAlertsOrganization] = useMutation(
    gql`
      mutation updateAlertsOrganization($input: AlertsUpdateOrganizationInput!) {
        alerts_updateOrganization(input: $input) {
          payload {
            id
          }
          message
          success
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const commodityFilters = [
    <ReferenceInput
      key="companyFilter"
      reference="companies"
      source="company_id"
      alwaysOn
      perPage={5}
      sort={{ field: 'score', order: 'DESC' }}
    >
      <AutocompleteInput
        key="companyAutoCompleteFilter"
        label="Company"
        source="company_id"
        optionText="name"
        disableClearable
        filterToQuery={(q) => {
          if (!Number.isNaN(parseInt(q, 10))) {
            return { id: q };
          }
          return { name: q };
        }}
      />
    </ReferenceInput>,
    <SelectArrayInput key="commodityFilter" source="commodity_id" alwaysOn choices={commodityChoices} />,
  ];

  useEffect(() => {
    (async () => {
      const data = await fetchCommodityChoices();
      setCommodityChoices(data);
    })();
  }, []);

  return (
    <CraftEdit
      transform={transformValues}
      mutationOptions={{
        onMutate: async (variables) => {
          const inputParams = transformAlertServiceValues(variables as OrganizationConfig);
          const { errors, data } = await updateAlertsOrganization({
            variables: {
              input: inputParams,
            },
          });

          const realData = data.alerts_updateOrganization;

          if (errors || !realData?.success) {
            notify(errors?.[0]?.message || realData?.message, { type: 'error' });
            return null;
          }

          notify('Alert company updated', { type: 'success' });
          return refresh();
        },
      }}
      header={
        <Stack gap={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h5">
              <OrganizationReferenceField source="org_id" />
            </Typography>
          </Stack>
          <Labeled label="Created">
            <DateField source="created_at" showTime />
          </Labeled>
        </Stack>
      }
      mutationMode="pessimistic"
    >
      <WithRecord
        render={(record) => (
          <CraftTabbedForm
            formType="edit"
            deleteOptions={{ deletePermission: 'alerts:delete', hideDeleteButton: true }}
            mutationMode="pessimistic"
          >
            <FormTab label="Config">
              <CraftPageSection title="Organization config">
                <TextInput source="id" disabled />
                <TextInput source="api_client_id" label="API Client ID" />
                <BooleanInput source="enable_alerts_processing" label="Enable Alerts Processing" />
                <CheckboxGroupInput
                  row={false}
                  label="Alerts"
                  source="datasets"
                  choices={ORGANIZATION_ALERTS_CONFIG_DATASETS}
                  onClick={handleCheckboxGroupInputClick}
                  sx={checkboxGroupInputDefaultCursorSx}
                />
                <CheckboxGroupInput
                  row={false}
                  label="Risks"
                  source="datasets"
                  choices={ORGANIZATION_RISK_CONFIG_DATASETS}
                  onClick={handleCheckboxGroupInputClick}
                  sx={checkboxGroupInputDefaultCursorSx}
                />
              </CraftPageSection>
            </FormTab>
            <FormTab label="Company Subscriptions">
              <SubscriptionTab type="company" label="Company Subscriptions" />
            </FormTab>
            <FormTab label="Location Subscriptions">
              <SubscriptionTab type="location" label="Location Subscriptions" />
            </FormTab>
            <FormTab label="Resource Subscriptions">
              <SubscriptionTab type="resource" label="Resource Subscriptions" />
            </FormTab>
            <FormTab
              label="Commodity Alerts"
              disabled={!record?.datasets.find((dataset: string) => dataset === 'news_commodities')}
            >
              <CraftPageSection title="Commodity Alerts">
                {record?.datasets.find((dataset: string) => dataset === 'news_commodities') ? (
                  <CraftStandaloneList
                    resource="company_commodities"
                    perPage={10}
                    filters={commodityFilters}
                    filter={{ org_id: record.org_id }}
                    exporter={handleCompanyCommodityExporter}
                    actions={
                      <>
                        {canDelete && <BulkDeleteCompanyCommodities refresh={refresh} />}
                        {canDelete && <DeleteAllCompanyCommodities refresh={refresh} />}
                        {canEdit && <BulkImportCompanyCommodities refresh={refresh} />}
                        {canEdit && (
                          <StyledLink to={companyCommodityCreateRoute} state={{ orgId }}>
                            <Button startIcon={<AddRoundedIcon />} sx={{ height: '2rem' }}>
                              Create
                            </Button>
                          </StyledLink>
                        )}
                        <Box sx={{ px: 0.5, py: 1 }}>
                          <ExportButton
                            resource="company_commodities"
                            maxResults={5000}
                            size="medium"
                            sx={{ height: '2rem' }}
                          />
                        </Box>
                      </>
                    }
                    dataGridProps={{
                      empty: canEdit ? (
                        <Center sx={{ marginTop: '1rem' }}>
                          No commodities found. Click
                          <StyledLink to={companyCommodityCreateRoute} state={{ orgId }}>
                            here
                          </StyledLink>{' '}
                          to create a company commodity
                        </Center>
                      ) : (
                        <Center sx={{ marginTop: '1rem' }}>No commodities found.</Center>
                      ),
                      bulkActionButtons: <BulkExportButton resource="company_commodities" label="Bulk Export" />,
                    }}
                  >
                    <CompanyReferenceField source="company_id" />
                    <TextField source="commodity_id" />
                    {canDelete ? (
                      <WrapperField label="Actions">
                        {canDelete && (
                          <DeleteButton
                            refresh={() => {
                              redirect(commodityDeleteButtonRedirectTo);
                              refresh();
                            }}
                          />
                        )}
                      </WrapperField>
                    ) : (
                      hiddenSpan
                    )}
                  </CraftStandaloneList>
                ) : (
                  <Box>
                    <Typography variant="body1">Commodity Alerts are not enabled for this organization.</Typography>
                    <Typography variant="body1">
                      Please enable Commodity Alerts in the Organization Config tab to view Commodity Alerts.
                    </Typography>
                    <Typography variant="body1">
                      Click <Link to={`/organization_config/${record.org_id}`}>here</Link> to edit the Organization
                      Config.
                    </Typography>
                  </Box>
                )}
              </CraftPageSection>
            </FormTab>
          </CraftTabbedForm>
        )}
      />
    </CraftEdit>
  );
};

export default OrgConfigEdit;
