import { useRecordContext, useNotify } from 'react-admin';
import CraftDeleteButtonWithConfirmation from '../../../components/CraftDeleteButtonWithConfirmation';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import client from '../../../data/api';

export const DeleteButton = ({ refresh }: { refresh: () => void }) => {
  const notify = useNotify();
  const record = useRecordContext();

  const [deleteEntitySubscription, { loading }] = useMutation(
    gql`
      mutation deleteEntitySubscription(
        $organizationId: ID!
        $topicName: String!
        $companySubscription: Boolean!
        $commoditySubscription: Boolean!
        $locationSubscription: Boolean!
      ) {
        alerts_deleteCompanySubscriptionByTopic(input: { organizationId: $organizationId, topicName: $topicName })
          @include(if: $companySubscription) {
          success
          message
          payload {
            id
          }
        }

        alerts_deleteCommoditySubscriptionByTopic(input: { organizationId: $organizationId, topicName: $topicName })
          @include(if: $commoditySubscription) {
          success
          message
          payload {
            id
          }
        }

        alerts_deleteLocationSubscriptionByTopic(input: { organizationId: $organizationId, topicName: $topicName })
          @include(if: $locationSubscription) {
          success
          message
          payload {
            id
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const [deleteEntitySubscriptionOld, { loading: loadingOld }] = useMutation(
    gql`
      mutation deleteEntitySubscriptionOld($id: uuid!) {
        signals_delete_organization_subscriptions_by_pk(id: $id) {
          id
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const handleDelete = async () => {
    const entityType = record.topic.split(':')[0];
    const { errors, data } = await deleteEntitySubscription({
      variables: {
        organizationId: record.org_id,
        topicName: record.topic,
        companySubscription: entityType.includes('company'),
        commoditySubscription: entityType.includes('resource'),
        locationSubscription: entityType.includes('location'),
      },
    });

    const realData =
      data.alerts_deleteCompanySubscriptionByTopic ||
      data.alerts_deleteCommoditySubscriptionByTopic ||
      data.alerts_deleteLocationSubscriptionByTopic;

    if (errors || !realData?.success) {
      notify(errors?.[0]?.message || realData?.message, { type: 'error' });
      return null;
    }

    await deleteEntitySubscriptionOld({
      variables: {
        id: record.id,
      },
    });

    notify('Organization subscription deleted', { type: 'success' });
    return refresh();
  };

  return (
    <CraftDeleteButtonWithConfirmation
      onDelete={handleDelete}
      loading={loading || loadingOld}
      title={`Delete organization subscription #${record && record.id}`}
      content="Are you sure you want to delete this item?"
    />
  );
};

export default DeleteButton;
