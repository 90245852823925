import { Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { InputProps, TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { JsonInput } from 'src/inputs/JsonInput';
import { JsonField } from 'src/fields/JsonField';

// TODO: Consider replacing all usage of JsonInput with this component.
const JsonWithRawOptionInput = (props: InputProps) => {
  const { label, source, isRequired, disabled } = props;
  const commonInputProps = { ...props, label: false as const };

  const { getFieldState } = useFormContext();
  const { error } = getFieldState(source);
  const [inputMode, setInputMode] = useState<'editor' | 'raw'>('editor');

  return (
    <>
      <Typography variant="caption" color={error ? 'error' : 'text.secondary'}>
        {label}
        {isRequired ? ' *' : ''}
      </Typography>

      <Paper sx={{ p: 2, pb: 4, width: '100%', overflowX: 'auto' }}>
        <Stack mb={1}>
          <ToggleButtonGroup
            exclusive
            value={inputMode}
            color="primary"
            onChange={(_e, val) => {
              if (!val) return;
              setInputMode(val);
            }}
          >
            <ToggleButton value="editor" size="small">
              Editor
            </ToggleButton>
            <ToggleButton value="raw" size="small">
              Raw
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        {inputMode === 'editor' &&
          (disabled ? (
            // There's not an effective way of "disabling" JsonInput, so we just render the field
            // version for now.
            <JsonField source={source} />
          ) : (
            <JsonInput {...commonInputProps} />
          ))}
        {inputMode === 'raw' && (
          <TextInput
            {...commonInputProps}
            multiline
            format={(val) => (!val || typeof val === 'string' ? val : JSON.stringify(val))}
            parse={(val) => {
              try {
                return JSON.parse(val);
              } catch {
                return val;
              }
            }}
          />
        )}
      </Paper>
    </>
  );
};

export default JsonWithRawOptionInput;
