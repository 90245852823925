import { useState } from 'react';
import { Button, Confirm, useRecordContext, useNotify } from 'react-admin';
import { Box } from '@mui/material';
import { client } from '../../../data/api';
import gql from 'graphql-tag';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { getOrgId, generateErrorMessage, buttonStyles } from 'src/utils/organizationSubscriptions';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

const tabSubscriptionMap = {
  '1': 'company',
  '2': 'location',
  '3': 'resource',
} as const;

export const DeleteAll = ({ refresh }: { refresh: () => void }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const record = useRecordContext();
  const notify = useNotify();
  const buttonClasses = buttonStyles();
  const location = useLocation();
  const tabIndex = location.pathname.split('/').pop() as keyof typeof tabSubscriptionMap;

  const [deleteAllAlertsSubscriptions] = useMutation(
    gql`
      mutation deleteAllAlertsSubscriptions(
        $organizationId: ID!
        $companySubscription: Boolean!
        $commoditySubscription: Boolean!
        $locationSubscription: Boolean!
      ) {
        alerts_deleteAllCompanySubscriptionsByOrgId(organizationId: $organizationId)
          @include(if: $companySubscription) {
          success
          message
        }

        alerts_deleteAllCommoditySubscriptionsByOrgId(organizationId: $organizationId)
          @include(if: $commoditySubscription) {
          success
          message
        }

        alerts_deleteAllLocationSubscriptionsByOrgId(organizationId: $organizationId)
          @include(if: $locationSubscription) {
          success
          message
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const handleDeleteAll = async () => {
    const orgId = (record && record.org_id) || getOrgId();
    if (!orgId) {
      setOpen(false);
      setIsLoading(false);
      return notify('No organization id found');
    }
    try {
      const { errors, data: alertsServiceData } = await deleteAllAlertsSubscriptions({
        variables: {
          organizationId: orgId,
          companySubscription: tabSubscriptionMap[tabIndex] === 'company',
          commoditySubscription: tabSubscriptionMap[tabIndex] === 'resource',
          locationSubscription: tabSubscriptionMap[tabIndex] === 'location',
        },
      });

      const realData =
        alertsServiceData.alerts_deleteAllCompanySubscriptionsByOrgId ||
        alertsServiceData.alerts_deleteAllCommoditySubscriptionsByOrgId ||
        alertsServiceData.alerts_deleteAllLocationSubscriptionsByOrgId;

      if (errors || !realData?.success) {
        notify(errors?.[0]?.message || realData?.message, { type: 'error' });
        return null;
      }

      const { data } = await client.mutate<{
        signals_delete_organization_subscriptions: { affected_rows: number };
      }>({
        mutation: gql`
          mutation DeleteAllOrganizationSubscriptions($where: organization_subscriptions_bool_exp!) {
            signals_delete_organization_subscriptions(where: $where) {
              affected_rows
            }
          }
        `,
        variables: {
          where: {
            org_id: {
              _eq: orgId,
            },
            topic: {
              _ilike: `%${tabSubscriptionMap[tabIndex]}%`,
            },
          },
        },
      });

      setIsLoading(false);
      setOpen(false);
      const numAffectedRows = data?.signals_delete_organization_subscriptions?.affected_rows || 0;
      notify(`${numAffectedRows.toLocaleString()} organizations subscriptions deleted`);

      return refresh();
    } catch (error) {
      setOpen(false);
      setIsLoading(false);
      const errorMessage = generateErrorMessage({
        error,
        prependMessage: 'Error deleting organization subscriptions\n',
      });
      return notify(errorMessage, { type: 'error' });
    }
  };
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    await handleDeleteAll();
  };

  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem', minWidth: '8.5rem' }}>
      <>
        <Button label="Delete All" onClick={handleClick} size="medium" className={buttonClasses.deleteButton}>
          <DeleteForeverIcon />
        </Button>
        {!isLoading && (
          <Confirm
            isOpen={open}
            title={`Delete all organization subscriptions for Org Id #${record && record.id}`}
            content="Are you sure you want to delete all subscriptions for this organization?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
            confirm="Delete All"
            className={buttonClasses.confirmDeleteButton}
          />
        )}
      </>

      {isLoading && <LoadingOverlay open />}
    </Box>
  );
};

export default DeleteAll;
