import { CREATE, GET_LIST, GET_ONE, GET_MANY_REFERENCE, GET_MANY, UPDATE, DELETE_MANY, DELETE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import OrgSubCreate from './Create';
import OrgSubList from './List';
import gql from 'graphql-tag';

export const OrganizationSubscriptionsResource: ResourceDefinition = {
  name: 'organization_subscriptions',
  section: ResourceSections.ALERTS,

  list: OrgSubList,
  create: OrgSubCreate,

  permissions: {
    list: ['alerts:read'],
    create: ['alerts:edit'],
  },

  extension: {
    operations: {
      [CREATE]: {
        operationName: 'signals_insert_organization_subscriptions',
      },
      [GET_LIST]: {
        operationName: 'signals_organization_subscriptions',
      },
      [GET_ONE]: {
        operationName: 'signals_organization_subscriptions',
      },
      [GET_MANY]: {
        operationName: 'signals_organization_subscriptions',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_organization_subscriptions',
      },
      [UPDATE]: {
        operationName: 'signals_update_organization_subscriptions',
      },
      [DELETE]: {
        operationName: 'signals_delete_organization_subscriptions',
      },
      [DELETE_MANY]: {
        operationName: 'signals_delete_organization_subscriptions',
      },
    },

    extraFields: gql`
      {
        topic_metadata {
          topic
          metadata
        }
      }
    `,

    transformVariables(ctx) {
      if (ctx.operation.type === 'CREATE') {
        const fieldName = ctx.queryType.args.find((arg) => arg.description === 'the rows to be inserted')?.name;

        if (fieldName) {
          ctx.variables[fieldName] = ctx.operation.params.data || [];
        }
      }

      return ctx;
    },
  },
};
